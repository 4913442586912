import { jsPDF } from 'jspdf'
import moment from 'moment'
import 'svg2pdf.js'

import { SaleForReceipt } from 'src/types'
import { colors } from 'src/utils/colorVariables'
import { ECountry } from 'src/state/effector/users/user.types'

import { RobotoNormal, RobotoBold } from './roboto-fonts'
import { logoSvg } from './logo-svg'

// A4 size = 595 × 842 pts

interface Props {
  sale: SaleForReceipt
  address: string
  nftData: { name: string; price: string }[]
  infos: string[]
}

export const generatePDF = async ({ sale, address, nftData, infos }: Props) => {
  const xOffset = 28
  let yOffset = 55
  const doc = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4', putOnlyUsedFonts: true })

  doc.addFileToVFS('RobotoNormal.ttf', RobotoNormal)
  doc.addFileToVFS('RobotoBold.ttf', RobotoBold)
  doc.addFont('RobotoNormal.ttf', 'Roboto', 'normal')
  doc.addFont('RobotoBold.ttf', 'Roboto', 'normal', 'bold')

  // HEADER
  const span = document.createElement('span')
  span.innerHTML = logoSvg
  const svg = span.firstChild

  if (svg) {
    await doc.svg(svg as Element, {
      x: 595 - xOffset - 132, // Horizontal offset
      y: yOffset, // Vertical offset
      width: 132,
      height: 22.5
    })
  }
  doc.setFont('Roboto')
  doc.setFontSize(30)
  doc.text('Receipt', xOffset, yOffset, { baseline: 'top' })
  yOffset += 30

  yOffset += 10.97

  doc.setFont('Roboto', 'bold')
  doc.setFontSize(10.5)
  doc.text(
    `No. ${sale.country === ECountry.NonUS ? 'WO' : 'US'}${sale.receiptId}`,
    xOffset,
    yOffset,
    { baseline: 'top' }
  )
  yOffset += 10.5

  yOffset += 41.76

  doc.setFontSize(9)
  doc.text('DATE:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 9
  yOffset += 3.46

  doc.setFont('Roboto', 'normal')
  doc.setFontSize(10.5)
  doc.text(moment(sale.createdAt).format('DD MMMM YYYY'), xOffset, yOffset, {
    baseline: 'top'
  })
  yOffset += 10.5

  yOffset += 18.45

  doc.setFont('Roboto', 'bold')
  doc.setFontSize(9)
  doc.text('BILL TO:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 9
  yOffset += 3.46

  if (sale.user.name) {
    doc.setFont('Roboto', 'normal')
    doc.setFontSize(10.5)
    doc.text(sale.user.name, xOffset, yOffset, { baseline: 'top' })
    yOffset += 10.5
    yOffset += 3.46
  }

  const addressInfos = address.split('\n')

  addressInfos.map((text, index) => {
    doc.text(text, xOffset, yOffset, { baseline: 'top' })
    yOffset += 10.5
    if (index < addressInfos.length - 1) {
      yOffset += 3.46
    }
  })

  yOffset += 18.45

  doc.setFont('Roboto', 'bold')
  doc.setFontSize(9)
  doc.text('TRANSACTION:', xOffset, yOffset, { baseline: 'top' })
  yOffset += 9

  yOffset += 3.46

  const fistPartTxHash = sale.txHash.slice(0, 48)
  const secondPartTxHash = sale.txHash.slice(48)
  doc.setFont('Roboto', 'normal')
  doc.setFontSize(10.5)
  doc.text(fistPartTxHash, xOffset, yOffset, { baseline: 'top' })
  yOffset += 10.5
  yOffset += 2.1
  doc.text(secondPartTxHash, xOffset, yOffset, { baseline: 'top' })
  yOffset += 10.5

  yOffset += 14.64

  doc.setDrawColor(colors.$grayLight)
  doc.setLineWidth(1)
  doc.line(xOffset, yOffset, 595 - 28, yOffset)
  yOffset += 1

  yOffset += 15.13

  doc.setFont('Roboto', 'bold')
  doc.setFontSize(10.5)
  doc.text('Description', xOffset, yOffset, { baseline: 'top' })
  doc.text('Amount', 595 - xOffset - doc.getTextWidth('Amount'), yOffset, { baseline: 'top' })
  yOffset += 10.5

  yOffset += 18.48

  doc.setFont('Roboto', 'normal')

  {
    nftData.map(({ name, price }) => {
      doc.text(name, xOffset, yOffset, { baseline: 'top' })
      doc.text(price, 595 - xOffset - doc.getTextWidth(price), yOffset, {
        baseline: 'top'
      })
      yOffset += 10.5

      yOffset += 18.48
    })
  }

  doc.setDrawColor(colors.$grayLight)
  doc.setLineWidth(1)
  doc.line(xOffset, yOffset, 595 - 28, yOffset)
  yOffset += 1

  yOffset += 15.52

  const investment = `$ ${Number(sale.investment).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`

  doc.setFont('Roboto', 'bold')
  doc.text('Payment Due', xOffset, yOffset, { baseline: 'top' })
  doc.text(investment, 595 - xOffset - doc.getTextWidth(investment), yOffset, {
    baseline: 'top'
  })
  yOffset += 10.5

  yOffset += 18.48

  doc.text('Payment Received', xOffset, yOffset, { baseline: 'top' })
  doc.text(investment, 595 - xOffset - doc.getTextWidth(investment), yOffset, {
    baseline: 'top'
  })
  yOffset += 10.5

  yOffset += 18.48

  const balanceDue = `$ ${Number(0).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`
  doc.text('Balance Due (USD)', xOffset, yOffset, { baseline: 'top' })
  doc.text(balanceDue, 595 - xOffset - doc.getTextWidth(balanceDue), yOffset, {
    baseline: 'top'
  })
  yOffset += 10.5

  yOffset += 47.47

  doc.setFont('Roboto', 'normal')
  infos.map((txt, index) => {
    doc.text(txt, xOffset, yOffset, { baseline: 'top' })
    yOffset += 10.5
    if (index < infos.length - 1) {
      yOffset += 4
    }
  })

  const restOffset = 842 - 40 - 11.4 - yOffset

  yOffset += restOffset
  doc.setFontSize(11.4)

  doc.text('NEED HELP? CONTACT US 24/7: SUPPORT@SIXTHSOCIETY.COM', 595 / 2, yOffset, {
    baseline: 'top',
    align: 'center'
  })

  doc.save(`receipt-${sale.country === ECountry.NonUS ? 'WO' : 'US'}${sale.receiptId}.pdf`)
}

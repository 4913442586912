import ethereumLogoUrl from '../assets/ethereum.svg'
import { ENetworkTypes } from '../types/common.types'

export const SUPPORTED_CHAIN = (
  process.env.REACT_APP_SUPPORTED_CHAIN ? +process.env.REACT_APP_SUPPORTED_CHAIN : 11155111
) as number

export const CHAIN_INFO = {
  1: {
    blockExplorerUrls: ['https://etherscan.io/'],
    chainName: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.ETH
  },
  11155111: {
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    chainName: 'Sepolia',
    nativeCurrency: { name: 'Sepolia ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.SEPOLIA
  }
} as {
  [key: number]: {
    blockExplorerUrls: string[]
    chainName: string
    nativeCurrency: { name: string; symbol: string; decimals: number }
    iconUrls: string
    networkType: ENetworkTypes
  }
}

export const MEDIA_WIDTHS = {
  upToExtremelySmall: 370,
  upToExtraSmall: 599,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

export const allowedAccounts = [
  '0x1ba4382AA387337207f0D83Bd2a54335c27F7F51',
  '0x2CAe7aFd84e9954b2c2a5F3A86f7d9611E14e586',
  '0xb4aeCe8B84EfcC52a91B4564B66dC9628285B76f',
  '0x7a1790298C86CF6165a0245a959C4Ee6c98084AB',
  '0x7D07Bc242109f0e8338Fa07a1041D932bf7C180c',
  '0xC94c9148DFe65C9a49b2259a4091Ec2FA9315966',
  '0x83313cc2C7947A3c7609122589e0Fce77d6cc54B',
  '0x4DDd426E0882F93B3656245F4363fb448D7917Da',
  '0xd8170fA55F79A799CeD161A16A1C26f41E99f908',
  '0xC2a5f62eA8e9F3aDaeA615FE597bfC27700551d5',
  '0x614066b55cf1f993abef4b1e043e87460c9ce26e',
  //ours
  '0xb305c1f2200a17E0502416B1746aB88C9B5C449f',
  '0x8066800991b5cFC768921cA18e3B2b5f04e4284c',
  '0x6c65F2A840280f345660aA5E8ABa708258cbB10c',
  '0x09f4Aa6e39888616D6eecD1aD1fE1F7ac10EB957',
  '0x5f20332a0611De6894eCbFB52d21A7a77784e89C',
  '0x7194834115a80b2c86586bccf4af1d9b91eb249c'
].map((el) => el.toLowerCase())
